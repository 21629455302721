//fonts

@mixin headerFont() {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}

@mixin textFont() {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-style: normal;
  line-height: 1.7rem;
  letter-spacing: 1.25px;
}

//colors

$footer-dark-blue: #1480fba9;
$dark-blue: #007acc;
$light-blue: #00bfd1;
$white: #fefefe;
$dark: #333;
$black: #000;
$red: #ff3d00;
$grey: #999;
$dark-grey: #767676;
$dark-white-background-color: #f9f9f9;

//box shadow
$box-shadow-low: #e0e1e322 10px 5px 15px;
$box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;

//margin
$standard-margin: 1rem;
$big-margin: 2rem;
$small-margin: 0.5rem;

//padding

$small-padding: 0.7rem;
$standard-padding: 1rem;
$big-padding: 2rem;

//hover scale size

$hover-min-scale: 1.01;
$hover-med-scale: 1.05;
$hover-max-scale: 1.1;

//transition time
$transition-time: 0.7s;

//border size

$border-min: 0.3rem;
$border-med: 0.5rem;
$border-max: 1rem;

//breakpoint

$mobile: 560px;
$tablet: 768px;
$big-tablet: 900px;
$small-desktop: 1024px;
$desktop: 1200px;
$large-desktop: 1700px;

//gaps
$small-gap: 0.5rem;
$standard-gap: 1rem;
$big-gap: 2rem;

//text sizes
$text-size: 1.1rem;
$btn-text-size: 1.4rem;

//mixins

@mixin large-header() {
  font-size: 3rem;
  color: $dark;
  text-align: left;
  @include headerFont();
  font-weight: 700;
  @media screen and (min-width: 700px) {
    text-align: center;
    font-size: 3.5rem;
  }
  @media screen and (min-width: 1200px) {
    font-size: 4.5rem;
    text-align: center;
  }
}

@mixin small-headers() {
  @include textFont();
  margin-top: $standard-margin;
  color: $dark-blue;
  font-size: 1rem;
  font-weight: 700;
  text-align: center;
}

@mixin below-headers() {
  text-align: center;
  @include headerFont();
  color: $dark;
  font-size: 1.5rem;
  line-height: 1.4;
  margin-top: 1rem;
  font-weight: 700;
}

@mixin category() {
  color: $orange;
  font-weight: 600;
}

@mixin description() {
  font-size: $text-size;
  @include textFont();
  color: $dark;
}

@mixin title($color) {
  font-size: 1.5rem;
  color: $color;
  @media screen and (min-width: $big-tablet) {
    font-size: 2rem;
  }
}

@mixin btn($backgroundColor, $color) {
  @include d-flex;
  gap: $small-gap;
  background-color: $backgroundColor;
  border: none;
  padding: 1.3rem $standard-padding;
  font-size: $btn-text-size;
  color: $color;
  cursor: pointer;
  transition: scale, border, $transition-time;
  will-change: scale, border;
  border-radius: $border-med;
  &:hover {
    scale: 1.05;
    background-color: transparent;
    color: $backgroundColor;
    border: 1px solid $backgroundColor;
  }
}

@mixin d-flex() {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

@mixin d-flex-column() {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
